import { useEffect, useRef, useState } from "react";
import './cupon-management.scss'
import Header from "../../header/header";
import DataManagement from "../data-management/data-management";
import { BASE_URL, smallScreen } from "../../../utils/constants";
import axios from "axios";
import { getUsersById } from "../../user/store/action";
import { useDispatch } from "react-redux";
import trashcan from "../../../utils/assets/trashcan.svg";
import Modal from 'react-bootstrap/Modal';

export default function CuponManagement() {
    const [list, setList] = useState([]);
    const [cuponCodeList, setCuponCodeList] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const isEffect = useRef('');
    const dispatch = useDispatch();
    const [cuponsListUpdate, setCuponsListUpdate] = useState([]);
    const isSmallScreen = smallScreen();
    const [cuponCodeFormData, setCuponCodeFormData] = useState({
        percentage: '',
        cuponCodeName: '',
        validity: ''
    });

    useEffect(() => {
        const fetchUsers = async () => {
            isEffect.current = true;
            let usersIdList = []
            list.forEach(item => {
                usersIdList.push({ id: item.userId })
            });
            const usersList = await dispatch(getUsersById(usersIdList))
            let paymentListWithNames = [...list]
            usersList.map((item, index) => paymentListWithNames[index] = {
                ...paymentListWithNames[index],
                userName: item?.customerName
            })
            setCuponsListUpdate(paymentListWithNames)
        }
        if (list && list.length > 0 && isEffect.current == false) { fetchUsers() }
    }, [list])

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${BASE_URL}/api/cuponCodeUses/get_all/`);
            console.log('response 🙏🙏🙏🙏🙏', response);
            setList(response.data)
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${BASE_URL}/api/cuponCode/get_all/`);
            console.log('response 🙏🙏🙏🙏🙏', response);
            setCuponCodeList(response.data)
        };
        fetchData();
    }, []);

    const headerListSortAndFilter = [
        { label: 'שם משתמש', type: 'userName', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'שם קופון', type: 'cuponCodeName', action: 'filter', subOptions: ['אות והדר 770', 'שמעון ביתאן', 'all'], index: 0 },
        { label: 'סכום מקורי', type: 'sumBeforeCupon', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'סכום לאחר הנחה', type: 'sum', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'תאריך שימוש', type: 'createdAt', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
    ]


    const deleteCuponCode = async (cuponCodeId) => {
        const object = { cuponCodeId: cuponCodeId }
        const response = await axios.post(`${BASE_URL}/api/cuponCode/delete/`, object);
        console.log('response 🙏🙏🙏🙏🙏', response);
        const updatedCuponCodeList = [...cuponCodeList].filter(item => item._id != cuponCodeId)
        setCuponCodeList(updatedCuponCodeList)
    }
    const deleteCuponUsesCode = () => {

    }

    const CuponCodeHandleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setCuponCodeFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        console.log(cuponCodeFormData);

    };


    const isCuponCodeFormValid = () => {
        const { percentage, cuponCodeName, validity } = cuponCodeFormData;
        return (
            percentage &&
            !isNaN(percentage) &&
            Number(percentage) > 0 &&
            Number(percentage) <= 100 &&
            cuponCodeName.trim() !== '' &&
            validity.trim() !== ''
        );
    };


    const onSubmit = async () => {
        try {
            if (isCuponCodeFormValid()) {
                console.log(cuponCodeFormData);
                const response = await axios.post(`${BASE_URL}/api/cuponCode/add/`, cuponCodeFormData);
                console.log('😡😡😡', response);

                if (response) {
                    alert('קוד הקופון נוצר בהצלחה')
                    const updatedCuponCodeList = [...cuponCodeList, response.data.object];
                    setCuponCodeList(updatedCuponCodeList)
                    setOpenModal(false)
                    setCuponCodeFormData({
                        percentage: '',
                        cuponCodeName: '',
                        validity: ''
                    })
                }
                else {
                    alert('קוד הקופון לא נוצר')
                }
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    return (
        <div className="cupon-management-styles">

            <Header page={'user-management'} context={['שימושים בקוד קופון']}></Header>

            <div className="cupon-wrapper">
                <div className="cupon-uses-wrapper">
                    <div className="data-manage-header">מימוש קוד קופון</div>
                    {cuponsListUpdate.length > 0 &&
                        <DataManagement
                            dataType={'cupon'}
                            dataList={cuponsListUpdate}
                            header={`${list.length} שימושים`}
                            headerList={headerListSortAndFilter}
                        ></DataManagement>}
                </div>

                <div className="cupon-code-wrapper">
                    <div className="data-manage-header">קופונים פעילים במערכת</div>
                    <div className="add-cupon-button" type='button' onClick={() => { setOpenModal(true) }}>הוספת קוד קופון</div>
                    <div className="cupon-list">
                        {cuponCodeList && cuponCodeList?.length > 0 ?
                            cuponCodeList.map((cupon) => {
                                return (
                                    <div className="name-trash-row">
                                        <div className="cupon-name"> {cupon.cuponCodeName} </div>
                                        <img src={trashcan} type='button' onClick={() => { deleteCuponCode(cupon._id) }}></img>
                                    </div>
                                )
                            })
                            :
                            <div>לא נמצאו קופונים במערכת</div>
                        }
                    </div>

                </div>
            </div>

            <Modal
                show={openModal}
                onHide={() => setOpenModal(false)}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='add-cupon-code-modal' >

                    <div className={`add-cupon-code-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className='cupon-code-header'>קוד קופון </div>
                        <form className='admin-edit-from-wrapper'>
                            <input
                                className="admin-edit-input"
                                type="number"
                                name={'percentage'}
                                value={cuponCodeFormData.percentage}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.length <= 3) {
                                        CuponCodeHandleChange(e);
                                    }
                                }}
                                placeholder={'אחוזי הנחה בקופון'}
                            />
                            <input
                                className="admin-edit-input"
                                type="text"
                                name={'cuponCodeName'}
                                value={cuponCodeFormData.cuponCodeName}
                                onChange={CuponCodeHandleChange}
                                placeholder={'שם הקופון'}
                            />
                            <input
                                className="admin-edit-input"
                                type="date"
                                name={'validity'}
                                value={cuponCodeFormData.validity}
                                onChange={CuponCodeHandleChange}
                                placeholder={'עד תאריך'}
                            />
                        </form>
                    </div>

                    <div className='admin-edit-button'>
                        <button
                            type="button"
                            className={`blue-button ${isCuponCodeFormValid() ? '' : 'disabled'} `}
                            onClick={onSubmit}
                            disabled={!isCuponCodeFormValid()}
                        >
                            שמירה
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

        </div>
    )
}