
import { Route, Routes, useNavigate } from 'react-router-dom';
import { NavBar } from '../../nav-bar/nav-bar.js';
import menuMobile from '../../../utils/assets/menu-mobile.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga4';
import { changeLanguage } from '../../user/store/action.js';
import { smallScreen } from '../../../utils/constants.js';
import AdminDashboard from '../admin-dashboard/admin-dashboard.js';
import UserManagement from '../user-management/user-management.js';
import DataManagement from '../data-management/data-management.js';
import MailManagement from '../mail-management/mail-management.js';
import Analytics from '../analytics/analytics.js';
import PaymentManagement from '../payment-management/payment-management.js';
import LoginForm from '../../user/login/login.js';
import ScanManagement from '../scan-management/scan-management.js';
import CuponManagement from '../cupon-management/cupon-management.js';
import Sponsers from '../sponsers/sponsers.js';

export default function LayoutDashboard() {
    const isReactGaEffect = useRef(true)
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isSmallScreen = smallScreen();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [showRenderPage, setShowRenderPage] = useState(false);

    useEffect(() => {
        if (isReactGaEffect.current == true && currentUser?.Role == 'user') {
            ReactGA.send({ hitType: "pageview", page: '/', title: 'one-page' });
            isReactGaEffect.current = false;
        }
    }, []);


    const changeLanguageFunc = async (newLanguage) => {
        try {
            setCurrentLanguage(newLanguage);
            await dispatch(changeLanguage(newLanguage));
        }
        catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="container-fluid" dir='rtl'>
            <div className={`row ${isSmallScreen ? 'navbar-row-mobile' : 'navbar-row'}`}>

                <div className={!isSmallScreen ? ` ${currentLanguage == 'en-US' ? 'navbar-col-ltr' : 'navbar-col'}` : ''} >
                    {(isSmallScreen) ?
                        <>
                            <div
                                type="button"
                                className={`container menu ${currentLanguage == 'en-US' ? 'ltr' : ''}  pt-2`}
                                onClick={() => setShowMenu(true)}>
                                <img src={menuMobile}></img>
                                {<div className='lenguage-wrapper mobile'
                                    onClick={(e) => { e.stopPropagation(); setShowMenu(false) }}>
                                    <div type={language == 'en-US' ? '' : 'button'} className={`col-5 lenguage ${language == 'en-US' ? 'lenguage-active mobile' : ''}`} onClick={() => changeLanguageFunc('en-US')}>EN</div>
                                    <div type={language == 'he' ? '' : 'button'} className={`col-7 lenguage ${language == 'he' ? 'lenguage-active mobile' : ''}`} onClick={() => changeLanguageFunc('he')}>עברית</div>
                                </div>}
                            </div>

                            <Offcanvas
                                placement={currentLanguage == 'en-US' ? 'start' : 'end'}
                                show={showMenu}
                                onHide={() => setShowMenu(false)}
                                id="OffcanvasMenu">
                                <NavBar setShowMenu={setShowMenu}></NavBar>
                            </Offcanvas>

                        </>
                        :
                        <div className={`${currentLanguage == 'en-US' ? '' : 'navbar-data'}`} >
                            <NavBar></NavBar>
                        </div>
                    }
                </div>

                <div className={`col ${isSmallScreen ? '' : (currentLanguage == 'en-US' ? 'body-col-ltr' : 'body-col')} `}>
                    <Routes>
                        <Route path="/admin-dashboard" element={<AdminDashboard />}></Route>
                        <Route path="/user-management" element={<UserManagement />}></Route>
                        <Route path="/scan-management" element={<ScanManagement />}></Route>
                        <Route path="/mail-management" element={<MailManagement />}></Route>
                        <Route path="/analytics" element={<Analytics />}></Route>
                        <Route path="/payment-management" element={<PaymentManagement />}></Route>
                        <Route path="/cupon-management" element={<CuponManagement />}></Route>
                        <Route path="/login" element={<LoginForm />}></Route>
                        <Route path="/sponsers" element={<Sponsers />}></Route>
                    </Routes>
                </div>

                <Modal
                    show={showRenderPage}
                    onHide={() => setShowRenderPage(false)}
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>

                    <Modal.Body className='pdf-modal' >
                        <Modal.Title>{'ארעה שגיאה בטעינת הדף'}</Modal.Title>
                        <button className='btn btn-primary' onClick={() => window.location.reload()}>נסה שוב</button>
                    </Modal.Body>
                </Modal >

            </div>
        </div>
    )
}

